<template>
  <b-card no-body class="mb-0">
    <div class="d-flex justify-content-start align-items-center">
      <!-- avatar -->
      <b-avatar :src="avatar" size="100px" rounded class="mr-1" />
      <!--/ avatar -->
      <div class="profile-user-info p-1">
        <h3 class="mb-0">
          {{ userData.title }}
        </h3>
        <h5 class="text-muted pb-1">{{desc}}</h5>
        <b-button variant="primary" style="max-width: fit-content;"><b-link :href="link" target="_blank" class="text-light">{{$t('go-porfile')}}</b-link></b-button>
      </div>
    </div>
  </b-card>
</template>
<script>
import { BCard, BAvatar, BLink, BButton } from "bootstrap-vue"
import utils from '@/libs/utils'
export default {
  components: {
    BCard,
    BAvatar,
    BLink,
    BButton
  },
  props: {
    userData: {
      type: Object,
      required: true
    },
    network: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      utils,
      avatar: ''
    }
  },
  mounted() {
    const self = this;
    utils.urlImagetoBase64(this.userData.avatar_url, function (result) {
      self.avatar = result;
    })
  },
  computed: {
    link() {
      const network = `https://${this.network}.com/`;
      const user =
        this.network === "youtube" ? `channel/${this.userData.id}` : this.network === "tiktok" ? `@${this.userData.username}` : this.userData.username;
      return `${network}${user}`;
    },
    desc() {
      if (this.userData.category_name) return `${this.$t('youtube.category')}:   ${ this.userData.category_name }`
      return `${this.userData.description.substr(0,150)}...`
    }
  }
};
</script>
